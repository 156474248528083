import { graphql } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

import Meta from 'components/meta'
import Layout from 'components/layout'

import { useJsonForm } from 'gatsby-tinacms-json'

let lang = 'de'

const EnWorkIndex = ({ location, data }) => {
  const [node] = useJsonForm(data.dataJson, WorkPageForm)
  const page = node.pages.work.de

  return (
    <Layout location={location}>
      <Helmet>
        <title>{page.site_title} - Reinhold Rößler</title>
      </Helmet>
      <header className="masthead work" style={{ paddingTop: `10rem` }}>
        <div className="container d-flex align-items-center">
          <div className="mx-auto text-center">
            <div className="" style={{ marginTop: `10em` }}>
              <h2 className="mt-2 desktop-only" style={{ color: `#ffffff` }}>
                {page.title}
              </h2>
            </div>
          </div>
        </div>
      </header>
      <div className="work-body">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h1 className="mobile-only">{page.title}</h1>
                <h3 style={{ color: `#c3c4d0` }}>
                  {page.company.for_companies_text}
                </h3>
                <h2 style={{ color: `#35424b` }}>
                  {page.company.leadership.text}
                </h2>
                <ul style={{ textAlign: `left` }}>
                  {page.company.leadership.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
                <h2 style={{ color: `#35424b` }}>
                  {page.company.project_management.text}
                </h2>
                <ul>
                  {page.company.project_management.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
                <h2 style={{ color: `#35424b` }}>
                  {page.company.teamwork.text}
                </h2>
                <ul>
                  {page.company.teamwork.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h1>{page.company.company_benefits.text}</h1>
                <ul>
                  {page.company.company_benefits.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h3 style={{ color: `#c3c4d0` }}>
                  {page.private.for_private_text}
                </h3>
                <h2 style={{ color: `#35424b` }}>{page.private.career.text}</h2>
                <ul style={{ textAlign: `left` }}>
                  {page.private.career.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-8 mx-auto">
                <h1>{page.private.private_benefits.text}</h1>
                <ul>
                  {page.private.private_benefits.points.map(item => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default EnWorkIndex

const WorkPageForm = {
  fields: [
    {
      name: `rawJson.pages.work.${lang}.site_title`,
      component: 'text',
      label: 'Site Title',
      required: true,
    },
    {
      name: `rawJson.pages.work.${lang}.title`,
      component: 'text',
      label: 'Title',
      required: true,
    },
    {
      label: 'Company',
      name: `rawJson.pages.work.${lang}.company`,
      description: 'Company info',
      component: 'group',
      fields: [
        {
          name: 'for_companies_text',
          component: 'text',
          label: 'For Companies',
          required: true,
        },
        {
          label: 'Leadership',
          name: 'leadership',
          description: 'Contact info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Title',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
        {
          label: 'Project Management',
          name: 'project_management',
          description: 'Contact info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Title',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
        {
          label: 'Teamwork',
          name: 'teamwork',
          description: 'Contact info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Title',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
        {
          label: 'Benefits',
          name: 'company_benefits',
          description: 'Contact info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Title',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
      ],
    },
    {
      label: 'Private',
      name: `rawJson.pages.work.${lang}.private`,
      description: 'Private info',
      component: 'group',
      fields: [
        {
          name: 'for_private_text',
          component: 'text',
          label: 'For Private',
          required: true,
        },
        {
          label: 'Career',
          name: 'career',
          description: 'Career info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Text',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
        {
          label: 'Benefits',
          name: 'private_benefits',
          description: 'Benefits info',
          component: 'group',
          fields: [
            {
              name: 'text',
              component: 'text',
              label: 'Title',
              required: true,
            },
            {
              component: 'group-list',
              name: 'points',
              label: 'Points',
              required: true,
              fields: [
                {
                  label: 'Text',
                  name: 'text',
                  component: 'text',
                },
              ],
              itemProps: item => ({
                label: item.text ? item.text : 'Bullet Point',
                key: item,
              }),
            },
          ],
        },
      ],
    },
  ],
}

export const pageQuery = graphql`
  query {
    dataJson {
      pages {
        work {
          de {
            company {
              company_benefits {
                points {
                  text
                }
                text
              }
              for_companies_text
              leadership {
                points {
                  text
                }
                text
              }
              project_management {
                points {
                  text
                }
                text
              }
              teamwork {
                points {
                  text
                }
                text
              }
            }
            private {
              career {
                points {
                  text
                }
                text
              }
              for_private_text
              private_benefits {
                points {
                  text
                }
                text
              }
            }
            title
            site_title
          }
        }
      }
      rawJson
      fileRelativePath
    }
  }
`
